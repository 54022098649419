import React from 'react';
import { AssignNestedModels } from '../CLURDUtilities';
import DataModel from "./DataModel";
import Permissions from '../Permissions';

//mui
import Button from '@material-ui/core/Button';

//inputs
import TextInput from '../../components/Inputs/TextInput';
import NumberInput from '../../components/Inputs/NumberInput';
import SelectInput from '../../components/Inputs/SelectInput';
import NumberOrHexInput from '../../components/Inputs/NumberOrHexInput';
import SwitchInput from '../../components/Inputs/SwitchInput';
import LookupInput from '../../components/Inputs/LookupInput';

//icons
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

class Ingestor extends DataModel {
  constructor(data = BLANK) {
    super("ingestors", data);
  }

  static loadRequirements(ingestors) {
    return new Promise((resolve, reject) => {
      Promise.all([AssignNestedModels("translators", "translator_id", ingestors), AssignNestedModels("companies", "company_id", ingestors)]).then((result) => {
        resolve(ingestors);
      }).catch((error) => reject(error));
    });
  }

  static prepareForTable(ingestors, renderMenu, classes) {
    ingestors.forEach((ingestor) => {
      if (ingestor.translator_id === '') {
        ingestor.translator_display = "None";
      } else if (!ingestor.nested_translator) {
        ingestor.error = "This ingestor does not have a valid translator. Please update or delete this ingestor. ";
        ingestor.translator_display = <span className={classes.notFound}>Translator Not Found</span>;
      } else {
        ingestor.translator_display = ingestor.nested_translator.name;
      }
      if (!ingestor.nested_company) {
        if (!Boolean(ingestor.error)) ingestor.error = "";
        ingestor.account_name = <span className={classes.inheritedInformation}>Inherited</span>;;
      } else {
        ingestor.account_name = ingestor.nested_company.name;
      }
      if (Boolean(ingestor.handler_type)) {
        if (Boolean(Ingestor.handlerTypes(ingestor.handler_type))) {
          ingestor.handler_display = Ingestor.handlerTypes(ingestor.handler_type).display;
        } else {
          if (!Boolean(ingestor.error)) ingestor.error = "";
          ingestor.error += "This ingestor does not have a valid handler type. Please delete this ingestor. ";
          ingestor.handler_display = (<span className={classes.unset}>Unsupported Type</span>);
        }
      } else {
        ingestor.handler_display = (<span className={classes.unset}>Unset</span>);
      }
      if (Boolean(ingestor.listener_type)) {
        if (Boolean(Ingestor.listenerTypes(ingestor.listener_type))) {
          ingestor.listener_display = Ingestor.listenerTypes(ingestor.listener_type).display;
        } else {
          if (!Boolean(ingestor.error)) ingestor.error = "";
          ingestor.error += "This ingestor does not have a valid listener type (" + ingestor.listener_type + "). Please delete this ingestor. ";
          ingestor.listener_display = (<span className={classes.unset}>Unsupported Type</span>);
        }
      } else {
        ingestor.listener_display = (<span className={classes.unset}>Unset</span>);
      }
      ingestor.action = (<span className={classes.tableMenu}>{renderMenu(ingestor, true)}</span>);
    });
    return ingestors;
  }

  static convert_for_ui(ingestor) {
    delete ingestor.action;
    delete ingestor.select;
    delete ingestor.listener_display;
    delete ingestor.handler_display;
    let new_ingestor = JSON.parse(JSON.stringify(ingestor));
    if (new_ingestor.listener_type === "http_client") {
      if (new_ingestor.target_template && new_ingestor.target_template != "") {
        new_ingestor.listener.url = new_ingestor.target_template
      }
    }
    if (new_ingestor.listener_type === "tcp_modbus" || new_ingestor.listener_type === "snmp_polling" || new_ingestor.listener_type === "icmp_polling") {
      if (new_ingestor.target_template && new_ingestor.target_template != "") {
        new_ingestor.listener.host = new_ingestor.target_template
      }
    }
    if (new_ingestor.listener_type === "snmp_polling") {
      if (new_ingestor.listener.version !== "1" && new_ingestor.listener.version !== "2c" && new_ingestor.listener.version !== "3") {
        if (new_ingestor.listener.version === "v1" || new_ingestor.listener.version === "version1") {
          new_ingestor.listener.version = "1";
        } else if (new_ingestor.listener.version === "v2c" || new_ingestor.listener.version === "version2c") {
          new_ingestor.listener.version = "2c";
        } else if (new_ingestor.listener.version === "v3" || new_ingestor.listener.version === "version3") {
          new_ingestor.listener.version = "3";
        }
      }
    }
    if (new_ingestor.handler_type === "router") {
      let routes = new_ingestor.handler.routes;
      if (routes && Array.isArray(routes) && routes.length > 0) {
        routes.forEach((route) => {
          route.methods = route.methods.map((value) => ({ label: value, value: value }));
        });
      }
    }
    if (new_ingestor.listener_type === "cloud_gcp_pubsub_jci") {
    }
    if (new_ingestor.listener == null) {
      if (new_ingestor.listener_type && new_ingestor.listener_type !== "") {
        new_ingestor.listener = this.getDefaultListener(new_ingestor.listener_type);
      } else {
        new_ingestor.listener_type = '';
        new_ingestor.listener = {};
      }
    }
    if (new_ingestor.handler == null) {
      if (new_ingestor.handler_type && new_ingestor.handler_type !== "") {
        new_ingestor.handler = this.getDefaultHandler(new_ingestor.handler_type);
      } else {
        new_ingestor.handler_type = '';
        new_ingestor.handler = {};
      }
    }
    return new_ingestor;
  }

  static hexCheck(ingestor) {
    if (ingestor.listener_type !== "tcp_modbus") return true;
    let hex = /^0[xX][0-9a-fA-F]+$/g
    if (typeof ingestor.listener.params.value === "string" && !hex.test(ingestor.listener.params.value)) {
      return false;
    }
    if (ingestor.listener.params.and_mask === "string" && !hex.test(ingestor.listener.params.and_mask)) {
      return false;
    }
    if (ingestor.listener.params.or_mask === "string" && !hex.test(ingestor.listener.params.or_mask)) {
      return false;
    }
    return true;
  }

  static convert_for_api(ingestor) {
    let new_ingestor = JSON.parse(JSON.stringify(ingestor));
    if (new_ingestor.listener_type === "bacnet") {
      new_ingestor.listener.request_type = "read";
    }
    if (new_ingestor.handler_type === "router") {
      let routes = new_ingestor.handler.routes;
      if (routes && Array.isArray(routes) && routes.length > 0) {
        routes.forEach((route) => {
          route.handler_type = "passthrough";
          route.methods = route.methods.map(({ value }) => value);
        });
      }
    }
    if (new_ingestor.handler_type === "dbus") {
      if (new_ingestor.handler.type === "int32" || new_ingestor.handler.type === "int64" || new_ingestor.handler.type === "string") {
        new_ingestor.handler.dbus_map_key = null;
      }
    }
    if (new_ingestor.listener_type === "http_client") {
      if (new_ingestor.listener.url.includes("{{")) {
        new_ingestor.target_template = new_ingestor.listener.url;
      }
    }
    if (new_ingestor.listener_type === "snmp_polling" || new_ingestor.listener_type === "icmp_polling" || new_ingestor.listener_type === "tcp_modbus") {
      if (new_ingestor.listener.host.includes("{{")) {
        new_ingestor.target_template = new_ingestor.listener.host;
      }
    }
    if (new_ingestor.listener_type === "tcp_modbus") {
      if (typeof ingestor.listener.params.value === "string") {
        new_ingestor.listener.params.value = parseInt(ingestor.listener.params.value, 16);
      }
      if (typeof ingestor.listener.params.and_mask === "string") {
        new_ingestor.listener.params.and_mask = parseInt(ingestor.listener.params.and_mask, 16);
      }
      if (typeof ingestor.listener.params.or_mask === "string") {
        new_ingestor.listener.params.or_mask = parseInt(ingestor.listener.params.or_mask, 16);
      }
    }

    // if (new_ingestor.translator_id === 'none') {
    //   new_ingestor.translator_id = '';
    // }
    return new_ingestor;
  }

  static validateKeys(ingestor) {
    if (ingestor.handler_type !== "dbus") return true;
    if (ingestor.handler.type === "int32" || ingestor.handler.type === "int64" || ingestor.handler.type === "string") return true;
    const keys = ingestor.handler.map_keys;
    let validation_result = true;
    if (keys && Array.isArray(keys) && keys.length > 0) {
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (!key || key === "") {
          validation_result = false;
          break;
        }
      }
      return validation_result;
    } else return true;
  }

  static validateRoutes(ingestor) {
    if (ingestor.handler_type !== "router") return true;
    const routes = ingestor.handler.routes;
    let validation_result = true;
    if (routes && Array.isArray(routes) && routes.length > 0) {
      for (let i = 0; i < routes.length; i++) {
        const route = routes[i];
        if (!route) {
          validation_result = false;
          break;
        }
        if (!route.methods || !Array.isArray(route.methods) || route.methods.length === 0) {
          validation_result = false;
          break;
        }
        if (!route.path || route.path === "") {
          validation_result = false;
          break;
        }
      }
      return validation_result;
    } else return true;

  }

  static getDefaultListener(ingestor_type) {
    const inputs = this.listenerTypes(ingestor_type).inputs;
    if (!inputs || (Array.isArray(inputs) && inputs.length === 0)) return;
    let default_listener = {};
    inputs.forEach((input) => {
      default_listener[input.field] = input.default_value;
    });
    return default_listener;
  }

  static getDefaultHandler(ingestor_type) {
    const inputs = this.handlerTypes(ingestor_type).inputs;
    if (!inputs || (Array.isArray(inputs) && inputs.length === 0)) return;
    let default_handler = {};
    inputs.forEach((input) => {
      default_handler[input.field] = input.default_value;
    });
    return default_handler;
  }

  static validate(ingestor) {
    let validationMessages = {};
    // let o = editableCopy;
    // if (o.name == null || o.name.length == 0) {
    // 	validationMessages.name = ["Please name this software package."];
    // }
    // if (o.script == null || o.script.length == 0) {
    // 	validationMessages.script = ["Please include the script that will execute the installation."];
    // }
    // if (o.filename == null || o.filename.length == 0) {
    // 	validationMessages.filename = ["Please upload files to be used in the software installation."];
    // }
    // if (Object.keys(validationMessages).length > 0) {
    // 	validationMessages.valid = false;
    // } else {
    // 	validationMessages.valid = true;
    // }
    return validationMessages;
  }

  static get_input(input, object, onChange, classes) {
    const { display, field, type, default_value } = input;
    switch (type) {
      case "dbus_map_key":
        if (object.type === "int32" || object.type === "int64" || object.type === "string" || !Boolean(object.type)) return;
        const add_key = () => {
          let keys = object[field];
          if (!keys || (Array.isArray(keys) && keys.length === 0)) {
            keys = [""];
          } else {
            keys.push("");
          }
          onChange({ field: field, value: keys });
        };
        const remove_key = (pair_index) => {
          let new_value = object[field];
          new_value.splice(pair_index, 1);
          onChange({ field: field, value: new_value });
        };
        const update_key = (key_index, value) => {
          object[field][key_index] = value;
          onChange({ field: field, value: object[field] });
        };
        return (
          <div key={display + "_" + field}>
            <div className={classes.label}>Keys</div>
            {(!object[field] || object[field].length === 0) ?
              <div className={classes.noPairings}>No keys defined.</div>
              : ""}
            <div className={classes.noPairings}>Ensure the keys you define match the key type defined above.</div>
            {object[field] ? object[field].map((key, index) => (
              <div className={classes.targetHostMapPairRow} key={display + "_" + field + "_" + index}>
                <div className={classes.filterContainerItem}>
                  <TextInput
                    label="Key"
                    emitChange={({ value }) => update_key(index, value)}
                    priorState={key}
                    field="key"
                  />
                </div>
                <Button className={classes.deleteIcon} onClick={() => remove_key(index)}>
                  <DeleteIcon />
                </Button>
              </div>
            )) : ""}
            <Button
              variant="outlined"
              color="primary"
              className={classes.mainButton}
              onClick={add_key}
            >
              <span className={classes.buttonIcon}><AddIcon /></span>
              <span>Add Key</span>
            </Button>
          </div>
        );
        break;
      case "dbus_type":
        const dbus_type_options = [
          { display: "int64", value: "int64" }, { display: "int32", value: "int32" }, { display: "string", value: "string" },
          { display: "Dictionary of key:string to value:int32", value: "dict:string:int32" },
          { display: "Dictionary of key:string to value:int64", value: "dict:string:int64" },
          { display: "Dictionary of key:string to value:string", value: "dict:string:string" }
        ];
        return (
          <div className={classes.requestSelectInput} key={display + "_" + field}>
            <SelectInput
              label={display}
              emitChange={onChange}
              priorState={object[field]}
              field={field}
              options={dbus_type_options}
            />
          </div>
        );
        break;
      case "route":
        const add_route = () => {
          let routes = object[field];
          if (!routes || (Array.isArray(routes) && routes.length === 0)) {
            routes = [{}];
          } else {
            routes.push({});
          }
          onChange({ field: field, value: routes });
        };
        const remove_route = (pair_index) => {
          let new_value = object[field];
          new_value.splice(pair_index, 1);
          onChange({ field: field, value: new_value });
        };
        const update_route = (nested_field, pair_index, value) => {
          object[field][pair_index][nested_field] = value;
          onChange({ field: field, value: object[field] });
        };
        const methods = ["GET", "PUT", "POST", "DELETE", "PATCH"];
        return (
          <div key={display + "_" + field}>
            <div className={classes.label}>Routes</div>
            {(!object[field] || object[field].length === 0) ?
              <div className={classes.noPairings}>No routes defined.</div>
              : ""}
            {object[field] ? object[field].map((route, index) => (
              <div className={classes.routeContainer} key={display + "_" + field + "_" + index}>
                <div className={classes.routeInputSelect}>
                  <LookupInput
                    priorState={{ values: route.methods, suggestions: methods.map((m) => ({ label: m, value: m })) }}
                    placeholder="Select HTTP Methods"
                    label="Methods"
                    field="methods"
                    emitChange={({ value }) => update_route("methods", index, value)}
                  />
                </div>
                <div className={classes.routeInput}>
                  <TextInput
                    label="Path"
                    emitChange={({ value, field }) => update_route(field, index, value)}
                    priorState={route.path}
                    field="path"
                  />
                </div>
                <DeleteIcon onClick={() => remove_route(index)} className={classes.deleteIcon} />
              </div>
            )) : ""}
            <Button
              variant="outlined"
              color="primary"
              className={classes.mainButton}
              onClick={add_route}
            >
              <span className={classes.buttonIcon}><AddIcon /></span>
              <span>Add Route</span>
            </Button>
          </div>
        );
        break;
      case "bool":
        return (
          <SwitchInput
            key={display + "_" + field}
            initial={object[field]}
            emitChange={onChange}
            onLabel={display}
            offLabel={display}
            field={field}
            location="start"
          />
        );
        break;
      case "command":
        return (
          <TextInput
            key={display + "_" + field}
            label={display}
            multiline
            rows="3"
            emitChange={onChange}
            priorState={object[field]}
            field={field}
          />
        );
        break;
      case "time":
      case "int":
        return (
          <div key={display + "_" + field} className={classes.numberInput}>
            <NumberInput
              label={display}
              minimum={0}
              emitChange={onChange}
              priorState={object[field]}
              field={field}
            />
          </div>
        );
        break;
      case "target_host_map":
        const add_target_host = () => {
          let new_value = object[field];
          if (!new_value || !Array.isArray(new_value) || (Array.isArray(new_value) && new_value.length === 0)) {
            new_value = [["", ""]];
          } else {
            new_value.push(["", ""]);
          }
          onChange({ field: field, value: new_value });
        };
        const remove_target_host = (pair_index) => {
          let new_value = object[field];
          new_value.splice(pair_index, 1);
          onChange({ field: field, value: new_value });
        };
        const update_target_host_map = (index, pair_index, value) => {
          object[field][pair_index][index] = value;
          onChange({ field: field, value: object[field] });
        };
        return (
          <div key={display + "_" + field}>
            <div className={classes.label}>Target Host Pairings</div>
            {(!object[field] || object[field].length === 0) ?
              <div className={classes.noPairings}>No pairings defined.</div>
              : ""}
            {object[field] ? object[field].map(([a, b], index) => (
              <div className={classes.targetHostMapPairRow} key={display + "_" + field + "_" + index}>
                <div className={classes.targetHostMapItem}>
                  <TextInput
                    label="Unique ID"
                    emitChange={({ value }) => update_target_host_map(0, index, value)}
                    priorState={object[field][index][0]}
                    field={field}
                  />
                </div>
                <div className={classes.targetHostMapItem}>
                  <TextInput
                    label="Target Host IP Address"
                    emitChange={({ value }) => update_target_host_map(1, index, value)}
                    priorState={object[field][index][1]}
                    field={field}
                  />
                </div>
                <DeleteIcon onClick={() => remove_target_host(index)} className={classes.deleteOIDIcon} />
              </div>
            )) : ""}
            <Button
              variant="outlined"
              color="primary"
              className={classes.mainButton}
              onClick={add_target_host}
            >
              <span className={classes.buttonIcon}><AddIcon /></span>
              <span>Add Pairing</span>
            </Button>
          </div>
        );
        break;
      case "string":
        return (
          <TextInput
            key={display + "_" + field}
            label={display}
            emitChange={onChange}
            priorState={object[field]}
            field={field}
          />
        );
        break;
      case "version":
        const versions = [{ display: "Version 1", value: "1" }, { display: "Version 2c", value: "2c" }, { display: "Version 3", value: "3" }];
        return (
          <div key={display + "_" + field} className={classes.selectInput}>
            <SelectInput
              key={display + "_" + field}
              label={display}
              emitChange={onChange}
              priorState={object[field]}
              field={field}
              options={versions}
            />
          </div>
        );
        break;
      case "oids":
        const add_oid = () => {
          let oids = object[field];
          if (!oids || (Array.isArray(oids) && oids.length === 0)) {
            oids = [""];
          } else {
            oids.push("");
          }
          onChange({ field: field, value: oids });
        };
        const remove_oid = (pair_index) => {
          let new_value = object[field];
          new_value.splice(pair_index, 1);
          onChange({ field: field, value: new_value });
        };
        const update_oid = (oid_index, value) => {
          object[field][oid_index] = value;
          onChange({ field: field, value: object[field] });
        };
        return (
          <div key={display + "_" + field}>
            <div className={classes.label}>OIDs</div>
            {(!object[field] || object[field].length === 0) ?
              <div className={classes.noPairings}>No OIDs defined.</div>
              : ""}
            {(object[field] && Array.isArray(object[field])) ? object[field].map((value, index) => (
              <div className={classes.targetHostMapPairRow} key={display + "_" + field + "_" + index}>
                <div className={classes.targetHostMapItem}>
                  <TextInput
                    label="OID"
                    emitChange={({ value }) => update_oid(index, value)}
                    priorState={value}
                    field="OID"
                  />
                </div>
                <DeleteIcon onClick={() => remove_oid(index)} className={classes.deleteOIDIcon} />
              </div>
            )) : ""}
            <Button
              variant="outlined"
              color="primary"
              className={classes.mainButton}
              onClick={add_oid}
            >
              <span className={classes.buttonIcon}><AddIcon /></span>
              <span>Add OID</span>
            </Button>
          </div>
        );
        break;
      case "baud":
        let bauds = [1200, 2400, 4800, 9600, 19200, 38400, 57600, 115200, 230400];
        const options = bauds.map((baud) => ({ display: baud, value: baud }));
        return (
          <div key={display + "_" + field} className={classes.selectInput}>
            <SelectInput
              key={display + "_" + field}
              label={display}
              emitChange={onChange}
              priorState={object[field]}
              field={field}
              options={options}
            />
          </div>
        );
        break;
      case "modbus_params":
        let modbus_params = object[field];
        if (!modbus_params) modbus_params = {};
        const modbus_request_type_options = [
          { value: "read_coils", display: "Read Coils" }, { value: "read_inputs", display: "Read Inputs" },
          { value: "read_input_registers", display: "Read Input Registers" }, { value: "read_holding_register", display: "Read Holding Register" },
          { value: "read_fifo_queue", display: "Read FIFO Queue" }, { value: "write_single_coil", display: "Write Single Coil" },
          { value: "write_single_register", display: "Write Single Register" }, { value: "mask_write_register", display: "Mask Write Register" }
        ];
        const modbus_change = (input) => {
          if (!object[field]) object[field] = {};
          object[field][input.field] = input.value;
          onChange({ field: "params", value: object[field] });
        };
        return (
          <div key={display + "_" + field}>
            <div className={classes.requestSelectInput}>
              <SelectInput
                label="Request Type"
                emitChange={modbus_change}
                priorState={modbus_params.request_type}
                field="request_type"
                options={modbus_request_type_options}
              />
            </div>
            <TextInput
              label="Address"
              emitChange={modbus_change}
              priorState={modbus_params.address}
              field="address"
            />
            <NumberInput
              label="Quantity"
              minimum={0}
              emitChange={modbus_change}
              priorState={modbus_params.quantity}
              field="quantity"
            />
            <NumberOrHexInput
              label="Value"
              emitChange={modbus_change}
              priorState={modbus_params.value}
              field="value"
            />
            <NumberOrHexInput
              label="And Mask"
              emitChange={modbus_change}
              priorState={modbus_params.and_mask}
              field="and_mask"
            />
            <NumberOrHexInput
              label="Or Mask"
              emitChange={modbus_change}
              priorState={modbus_params.or_mask}
              field="or_mask"
            />
          </div>

        );
        break;
      case "opcua_params":
        let opcua_params = object[field];
        if (!opcua_params) opcua_params = {};
        const opcua_change = (input) => {
          if (!object[field]) object[field] = {};
          object[field][input.field] = input.value;
          onChange({ field: field, value: object[field] });
        };
        const opcua_request_type_options = [{ value: "read_single_int32", display: "Read Single Int32" }, { value: "write_single_int32", display: "Write Single Int32" }];
        return (
          <div key={display + "_" + field}>

            <div className={classes.requestSelectInput}>
              <SelectInput
                label="Request Type"
                emitChange={opcua_change}
                priorState={opcua_params.request_type}
                field="request_type"
                options={opcua_request_type_options}
              />
            </div>
            <TextInput
              label="Node ID"
              emitChange={opcua_change}
              priorState={opcua_params.node_id}
              field="node_id"
            />
            <TextInput
              label="Value"
              emitChange={opcua_change}
              priorState={opcua_params.value}
              field="value"
            />
          </div>
        );
        break;
      case "bacnet_params":
        const bacnet_object_type_options = [
          { value: "analog_value", display: "Analog Value" },
          { value: "analog_input", display: "Analog Input" },
          { value: "analog_output", display: "Analog Output" },
          { value: "large_analog_value", display: "Large Analog Value" },
          { value: "integer_value", display: "Integer Value" },
          { value: "positive_integer_value", display: "Positive Integer Value" },
          { value: "binary_value", display: "Binary Value" },
          { value: "binary_output", display: "Binary Output" },
          { value: "binary_input", display: "Binary Input" },
          { value: "characterstring_value", display: "Characterstring Value" },
        ];
        let bacnet_params = object.params;
        const bacnet_change = (input) => {
          object["params"][input.field] = input.value;
          onChange({ field: "params", value: object.params });
        };
        return (
          <div className={classes.generalWrapper} key={display + "_" + field}>
            <SelectInput
              label="Object Type"
              emitChange={bacnet_change}
              priorState={bacnet_params.object_type}
              field="object_type"
              options={bacnet_object_type_options}
            />
            <div className={classes.numberInput}>
              <NumberInput
                label="Object Instance"
                emitChange={bacnet_change}
                priorState={bacnet_params.object_instance}
                field="object_instance"
              />
            </div>
            <TextInput
              label="Property ID"
              emitChange={bacnet_change}
              priorState={bacnet_params.property_id}
              field="property_id"
            />
            <TextInput
              label="Value"
              emitChange={bacnet_change}
              priorState={bacnet_params.value}
              field="value"
            />
          </div>
        );
        break;
      case "filter":
        //an array of these things
        /*Path      string `json:"path"` //string
        Interface string `json:"interface"` //string
        Member    string `json:"member"` //string*/
        const add_filter = () => {
          let filters = object[field];
          if (!filters || (Array.isArray(filters) && filters.length === 0)) {
            filters = [{}];
          } else {
            filters.push({});
          }
          onChange({ field: field, value: filters });
        };
        const remove_filter = (pair_index) => {
          let new_value = object[field];
          new_value.splice(pair_index, 1);
          onChange({ field: field, value: new_value });
        };
        const update_filter = (nested_field, pair_index, value) => {
          object[field][pair_index][nested_field] = value;
          onChange({ field: field, value: object[field] });
        };
        return (
          <div key={display + "_" + field}>
            <div className={classes.label}>Filters</div>
            {(!object[field] || object[field].length === 0) ?
              <div className={classes.noPairings}>No filters defined.</div>
              : ""}
            {object[field] ? object[field].map((filter, index) => (
              <div key={display + "_" + field + "_" + index} className={classes.filterContainer}>
                <div className={classes.filterContainerItem}>
                  <TextInput
                    label="Path"
                    emitChange={({ value, field }) => update_filter(field, index, value)}
                    priorState={filter.path}
                    field="path"
                  />
                </div>
                <div className={classes.filterContainerItem}>
                  <TextInput
                    label="Interface"
                    emitChange={({ value, field }) => update_filter(field, index, value)}
                    priorState={filter.interface}
                    field="interface"
                  />
                </div>
                <div className={classes.filterContainerItem}>
                  <TextInput
                    label="Member"
                    emitChange={({ value, field }) => update_filter(field, index, value)}
                    priorState={filter.member}
                    field="member"
                  />
                </div>
                <Button className={classes.deleteIcon} onClick={() => remove_filter(index)}>
                  <DeleteIcon />
                </Button>
              </div>
            )) : ""}
            <Button
              variant="outlined"
              color="primary"
              className={classes.mainButton}
              onClick={add_filter}
            >
              <span className={classes.buttonIcon}><AddIcon /></span>
              <span>Add Filter</span>
            </Button>
          </div>
        );
        break;
      case "device_type_mapping":
        //an array of these things
        /*
        Key      string
        Value string
        DeviceTypeId    string
        */
        const add_device_type_mapping = () => {
          let device_type_mappings = object[field];
          if (!device_type_mappings || (Array.isArray(device_type_mappings) && device_type_mappings.length === 0)) {
            device_type_mappings = [{}];
          } else {
            device_type_mappings.push({});
          }
          onChange({ field: field, value: device_type_mappings });
        };
        const remove_device_type_mapping = (pair_index) => {
          let new_value = object[field];
          new_value.splice(pair_index, 1);
          onChange({ field: field, value: new_value });
        };
        const update_device_type_mapping = (nested_field, pair_index, value) => {
          object[field][pair_index][nested_field] = value;
          onChange({ field: field, value: object[field] });
        };
        return (
          <div key={display + "_" + field}>
            <div className={classes.label}>Device Type Mappings</div>
            {(!object[field] || object[field].length === 0) ?
              <div className={classes.noPairings}>No Device Type Mappings defined.</div>
              : ""}
            {object[field] ? object[field].map((device_type_mapping, index) => (
              <div key={display + "_" + field + "_" + index} className={classes.deviceTypeMappingContainer}>
                <div className={classes.deviceTypeMappingContainerItem}>
                  <TextInput
                    label="Key Path"
                    emitChange={({ value, field }) => update_device_type_mapping(field, index, value)}
                    priorState={device_type_mapping.key}
                    field="key"
                  />
                </div>
                <div className={classes.deviceTypeMappingContainerItem}>
                  <TextInput
                    label="Value"
                    emitChange={({ value, field }) => update_device_type_mapping(field, index, value)}
                    priorState={device_type_mapping.value}
                    field="value"
                  />
                </div>
                <div className={classes.deviceTypeMappingContainerItem}>
                  <TextInput
                    label="Device Type ID"
                    emitChange={({ value, field }) => update_device_type_mapping(field, index, value)}
                    priorState={device_type_mapping.device_type_id}
                    field="device_type_id"
                  />
                </div>
                <Button className={classes.deleteIcon} onClick={() => remove_device_type_mapping(index)}>
                  <DeleteIcon />
                </Button>
              </div>
            )) : ""}
            <Button
              variant="outlined"
              color="primary"
              className={classes.mainButton}
              onClick={add_device_type_mapping}
            >
              <span className={classes.buttonIcon}><AddIcon /></span>
              <span>Add Device Type Mapping</span>
            </Button>
          </div>
        );
        break;
    }
  }

  static listenerTypeAllowedForIngestorType(listener_type, ingestor_type) {
    let iTypeMap = typesAllowedByIngestorType[ingestor_type];
    let allowed = false;
    if (iTypeMap != null) {
      let typeValue = iTypeMap["allowed_listener_types"].includes(listener_type);
      allowed = Boolean(typeValue);
    }
    return allowed;
  }

  static handlerTypeAllowedForIngestorType(handler_type, ingestor_type) {
    let iTypeMap = typesAllowedByIngestorType[ingestor_type];
    let allowed = false;
    if (iTypeMap != null) {
      let typeValue = iTypeMap["allowed_handler_types"].includes(handler_type);
      allowed = Boolean(typeValue);
    }
    return allowed;
  }

  static translatorTypeAllowedForIngestorType(translator_type, ingestor_type) {
    let iTypeMap = typesAllowedByIngestorType[ingestor_type];
    let allowed = false;
    if (iTypeMap != null) {
      let typeValue = iTypeMap["allowed_translator_types"].includes(translator_type);
      allowed = Boolean(typeValue);
    }
    return allowed;
  }

  static listenerTypes(listener_type) {
    // These maps define the listener types available, with the `inputs` section describing values that
    // will be stored in the ingestor's `listener_json`
    const map = {
      "shell_polling": {
        display: "Shell Polling",
        inputs: [
          { display: "Command", field: "command", type: "command", default_value: "" },
          { display: "Timeout (seconds)", field: "timeout", type: "time", default_value: 0 },
          { display: "Poll Interval", field: "poll_interval", type: "time", default_value: 0 }
        ]
      },
      "icmp_polling": {
        display: "ICMP Polling",
        inputs: [
          // {display: "Target Template (Ex: .Device.Metadata.host)", field: "target_template", type: "string", default_value: ""},
          { display: "Host ( Examples: 192.168.10.44, {{.Device.Metadata.host}} )", field: "host", type: "string", default_value: "" },
          { display: "Timeout (seconds)", field: "timeout", type: "time", default_value: 0 },
          { display: "Poll Interval", field: "poll_interval", type: "time", default_value: 0 },
        ]
      },
      "snmp_polling": {
        display: "SNMP Polling",
        inputs: [
          // {display: "Target Template (Ex: .Device.Metadata.host)", field: "target_template", type: "string", default_value: ""},
          { display: "Host ( Examples: 192.168.10.44, {{.Device.Metadata.host}} )", field: "host", type: "string", default_value: "" },
          { display: "Community", field: "community", type: "string", default_value: "" },
          { display: "Version", field: "version", type: "version", default_value: "1" },
          { display: "OIDs", field: "oids", type: "oids", default_value: [] },
          { display: "Timeout (seconds)", field: "timeout", type: "time", default_value: 0 },
          { display: "Retries", field: "retries", type: "int", default_value: 0 },
          { display: "Poll Interval", field: "poll_interval", type: "time", default_value: 0 },
        ]
      },
      "snmp_trap": {
        display: "SNMP Trap",
        inputs: [
          { display: "OIDs", field: "oids", type: "oids", default_value: [] },
        ]
      },
      "tcp_server": {
        display: "TCP Server",
        inputs: [
          { display: "Address", field: "address", type: "string", default_value: "" },
        ]
      },
      "tcp_client": {
        display: "TCP Client",
        inputs: [
          { display: "Address", field: "address", type: "string", default_value: "" },
        ]
      },
      "udp_server": {
        display: "UDP Server",
        inputs: [
          { display: "Address", field: "address", type: "string", default_value: "" },
        ]
      },
      "udp_client": {
        display: "UDP Client",
        inputs: [
          { display: "Address", field: "address", type: "string", default_value: "" },
        ]
      },
      "http": {
        display: "HTTP Server",
        inputs: [
          { display: "Port", field: "port", type: "int", default_value: 22 },
        ]
      },
      "http_client": {
        display: "HTTP Client",
        inputs: [
          // {display: "Target Template (Ex: .Device.Metadata.url)", field: "target_template", type: "string", default_value: ""},
          { display: "Method", field: "method", type: "string", default_value: "GET" },
          { display: "URL (Single: 'http://host.com/endpoint' Multiple: '{{.Device.Metadata.url}}' )", field: "url", type: "string", default_value: "" },
          { display: "Poll Interval", field: "poll_interval", type: "int", default_value: "" },
          { display: "Timeout", field: "timeout", type: "int", default_value: "" },
        ]
      },
      "dev": {
        display: "Linux Dev",
        inputs: [
          { display: "dev", field: "device", type: "string", default_value: "/dev/ttyS1" },
          { display: "Baud Rate", field: "baud", type: "baud", default_value: 1200 },
        ]
      },
      "tcp_modbus": {
        display: "TCP Modbus",
        inputs: [
          // {display: "Target Template (Ex: {{.Device.Metadata.host}})", field: "target_template", type: "string", default_value: ""},
          { display: "Host (Single: '192.168.10.44' Multiple: '{{.Device.Metadata.host}}' )", field: "host", type: "string", default_value: "" },
          { display: "Port", field: "port", type: "int", default_value: 22 },
          { display: "Slave ID", field: "slave_id", type: "int", default_value: 0 },
          { display: "Timeout (seconds)", field: "timeout", type: "time", default_value: 0 },
          { display: "Poll Interval", field: "poll_interval", type: "time", default_value: 0 },
          {
            display: "Params", field: "params", type: "modbus_params", default_value: {
              request_type: "read_coils",
              address: "",
              value: 0,
              quantity: 0,
              and_mask: 0,
              or_mask: 0
            }
          },
        ]
      },
      "opcua": {
        display: "OPC UA",
        inputs: [
          { display: "Host", field: "host", type: "string", default_value: "" },
          { display: "Port", field: "port", type: "int", default_value: 22 },
          { display: "Timeout (seconds)", field: "timeout", type: "time", default_value: 0 },
          { display: "Poll Interval", field: "poll_interval", type: "time", default_value: 0 },
          {
            display: "Params", field: "params", type: "opcua_params", default_value: {
              request_type: "read_single_int32",
              node_id: "",
              value: ""
            }
          },
        ]
      },
      "bacnet": {
        display: "BACnet",
        inputs: [
          { display: "Host", field: "host", type: "string", default_value: "" },
          { display: "Port", field: "port", type: "int", default_value: 22 },
          { display: "Timeout (seconds)", field: "timeout", type: "time", default_value: 0 },
          { display: "Poll Interval", field: "poll_interval", type: "time", default_value: 0 },
          {
            display: "Params", field: "params", type: "bacnet_params", default_value: {
              object_type: "analog_value",
              object_instance: 0,
              property_id: "",
              value: ""
            }
          },
        ]
      },
      "dbus_signal": {
        display: "D-Bus Signal",
        inputs: [
          { display: "Filters", field: "filters", type: "filter", default_value: [] },
        ]
      },
      "cloud": {
        display: "Cloud HTTP Ingest",
        inputs: []
      },
      "cloud_polling_geoforce": {
        display: "Geoforce Cloud Polling",
        inputs: [
					{ display: "Bearer Token", field: "bearer_token", type: "string", default_value: "" }
        ]
      },
      "cloud_polling_inmarsat": {
        display: "Inmarsat Cloud Polling",
        inputs: [
          { display: "Access ID", field: "access_id", type: "string", default_value: "" },
          { display: "Password", field: "password", type: "string", default_value: 0 },
          { display: "Minimum Timeout (Seconds)", field: "min_timeout_seconds", type: "int", default_value: 30 },
        ]
      },
      "cloud_polling_orbcomm": {
        display: "ORBCOMM Cloud Polling",
        inputs: [
          { display: "Access ID", field: "access_id", type: "string", default_value: "" },
          { display: "Password", field: "password", type: "string", default_value: 0 },
          { display: "Minimum Timeout (Seconds)", field: "min_timeout_seconds", type: "int", default_value: 30 },
        ]
      },
      "cloud_gcp_pubsub_jci": {
        display: "GCP Pub/Sub",
        inputs: [
          { display: "Subscription ID", field: "subscription_id", type: "string", default_value: "" },
          { display: "Integration ID", field: "integration_id", type: "string", default_value: "" },
          { display: "Auto Discover Device on Unrecognized Device ID?", field: "create_discovered_device", type: "bool", default_value: false },
          { display: "Discovered Device Heartbeat Period Keypath", field: "heartbeat_period", type: "string", default_value: "" },
          { display: "Auto Promote Discovered Device", field: "auto_promote_discovered_device", type: "bool", default_value: false },
          { display: "Device Type Mapping", field: "device_type_mapping", type: "device_type_mapping", default_value: [] },
        ]
      },
      "cloud_polling_assetlink": {
        display: "Assetlink Cloud Polling",
        inputs: [
          { display: "Username", field: "username", type: "string", default_value: "" },
          { display: "Password", field: "password", type: "string", default_value: 0 },
          { display: "Minimum Timeout (Seconds)", field: "min_timeout_seconds", type: "int", default_value: 30 },
        ]
      }
    };
    if (Boolean(listener_type)) {
      if (!map[listener_type]) return null;
      return map[listener_type]
    } else {
      return map;
    }
  }

  static handlerTypes(handler_type) {
    /*
    For the future when the listener csv is supported:
    "csv": {
        display: "CSV",
        inputs: [
          {display: "CSV Read Options", field: "csv_read_options", type: "int"}, //TYPE? delim: string (default is a comma, optional), fields_per_record: int, trim_leading_space (trims leading white space in a field, default is false, boolean)
        ]
      },
    */
    const map = {
      "fixed": {
        display: "Fixed",
        inputs: [
          { display: "Length", field: "length", type: "int", default_value: 0 },
        ]
      },
      "delimited": {
        display: "Delimited",
        inputs: [
          { display: "Delim", field: "delim", type: "string", default_value: "," },
          { display: "Strip Delimited", field: "strip_delim", type: "bool", default_value: false },
        ]
      },
      "router": {
        display: "Router",
        inputs: [
          { display: "Routes", field: "routes", type: "route", default_value: [] },
        ]
      },
      "dbus": {
        display: "D-Bus",
        inputs: [
          { display: "Index", field: "index", type: "int", default_value: 0 },
          { display: "Type", field: "type", type: "dbus_type", default_value: "int64" },
          { display: "Map Keys", field: "map_keys", type: "dbus_map_key", default_value: [] },
        ]
      },
      "passthrough": {
        display: "Passthrough",
        inputs: []
      },
    };
    if (Boolean(handler_type)) {
      if (!map[handler_type]) return null;
      return map[handler_type]
    } else {
      return map;
    }
  }
}

const BLANK = {
  name: "",
  company_id: "",

};

const typesAllowedByIngestorType = {
	"cloud": {
		"allowed_listener_types": [
			"cloud",
			"cloud_polling_inmarsat",
			"cloud_polling_orbcomm",
			"cloud_polling_assetlink",
			"cloud_gcp_pubsub_jci",
			"cloud_polling_geoforce",
		],
		"allowed_handler_types": [
			"",
			"passthrough"
		],
		"allowed_translator_types": [
			"gs",
			"template",
			"inmarsat",
			"orbcomm",
			"geoforce",
			"assetlink",
			"altair"
		]
	},
	"edge": {
		"allowed_listener_types": [
			"shell_polling",
			"snmp_polling",
			"icmp_polling",
			"tcp_server",
			"tcp_client",
			"udp_server",
			"udp_client",
			"http",
			"http_client",
			"dev",
			"tcp_modbus",
			"opcua",
			"bacnet",
			"dbus_signal"
		],
		"allowed_handler_types": [
			"fixed",
			"delimited",
			"router",
			"dbus",
			"passthrough"
		],
		"allowed_translator_types": [
			"template",
			"javascript"
		]
	}
};

export default Ingestor;
